import api from '../../api'

function processUserData (userData, data) {
  userData.uid = data.ID
  userData.hash = data.hash
  if (data.name) {
    userData.name = data.name
  }
  if (data.access_token) {
    userData.access_token = data.access_token
  }
  if (data.refresh_token) {
    userData.refresh_token = data.refresh_token
  }
  userData.defaultAvatar = data.default_avatar
  if (data.avatar != null) {
    try {
      userData.avatar = JSON.parse(data.avatar)
      userData.avatarSet = false
      if (typeof userData.avatar === 'object') {
        if (Object.values(userData.avatar).slice(0, -1).filter(el => el !== null).length > 0) {
          userData.avatarSet = true
        }
      }
    } catch (e) {
      userData.avatar = {
        horn: null,
        nose: null,
        mouth: null,
        body: null,
        bg: null,
        tool: null,
        avatarDirt: 0
      }
      userData.avatarSet = false
    }
  }

  if (data.progress != null) {
    try {
      userData.completedTopics = JSON.parse(data.progress)
    } catch (e) {
      userData.completedTopics = []
    }
  }
  if (data.completedChallenges != null) {
    try {
      userData.completedChallenges = JSON.parse(data.completedChallenges)
    } catch (e) {
      userData.completedChallenges = []
    }
  }

  if (data.answeredPolls != null) {
    try {
      userData.answeredPolls = JSON.parse(data.answeredPolls)
    } catch (e) {
      userData.answeredPolls = []
    }
  }

  return userData
}

const state = {
  users: [],
  currentUser: {
    avatar: {
      body: null,
      horn: null,
      nose: null,
      mouth: null,
      bg: null,
      tool: null,
      avatarDirt: 0
    },
    name: '',
    email: '',
    uid: '',
    hash: false,
    active: false,
    completedTopics: [],
    completedChallenges: [],
    answeredPolls: [],
    access_token: null,
    refresh_token: null,
    guideActive: true
  },
  code_challenge: null
}

const getters = {
  currentUser (state, getters) {
    if (state.currentUser && state.currentUser.avatar !== undefined && state.currentUser.avatar.body !== null && parseInt(state.currentUser.avatar.body) !== 0) {
      getters.avatarAssets.tool = getters.avatarAssets.body.filter(body => body.id === state.currentUser.avatar.body)[0].tools
      state.currentUser.avatarData = {
        body: state.currentUser.avatar.body !== undefined ? getters.avatarAssets.body.filter(body => body.id === state.currentUser.avatar.body)[0].body : false,
        horn: getters.avatarAssets.horn !== undefined ? getters.avatarAssets.horn.filter(horn => horn.id === state.currentUser.avatar.horn)[0] : false,
        nose: getters.avatarAssets.nose !== undefined ? getters.avatarAssets.nose.filter(nose => nose.id === state.currentUser.avatar.nose)[0] : false,
        mouth: getters.avatarAssets.mouth !== undefined ? getters.avatarAssets.mouth.filter(mouth => mouth.id === state.currentUser.avatar.mouth)[0] : false,
        bg: getters.avatarAssets.colors !== undefined ? getters.avatarAssets.colors.filter(color => color.id === state.currentUser.avatar.bg)[0] : false,
        tool: getters.avatarAssets.tool !== undefined ? getters.avatarAssets.tool.filter(mouth => mouth.id === state.currentUser.avatar.tool)[0] : false,
        title: getters.avatarAssets.body !== undefined ? getters.avatarAssets.body.filter(body => body.id === state.currentUser.avatar.body)[0].title : false
      }
    } else {
      state.currentUser.avatarData = {
        body: false,
        horn: false
      }
    }
    return state.currentUser
  },
  users (state, getters) {
    return state.users.filter(user => user.uid !== state.currentUser.uid).map(user => {
      if (user.avatar !== undefined && user.avatar.body !== null && parseInt(user.avatar.body) !== 0) {
        try {
          getters.avatarAssets.tool = getters.avatarAssets.body.filter(body => body.id === user.defaultAvatar)[0].tools
          user.avatarData = {
            body: user.avatar.body !== undefined ? getters.avatarAssets.body.filter(body => body.id === user.avatar.body)[0].body : false,
            horn: getters.avatarAssets.horn !== undefined ? getters.avatarAssets.horn.filter(horn => horn.id === user.avatar.horn)[0] : false,
            nose: getters.avatarAssets.nose !== undefined ? getters.avatarAssets.nose.filter(nose => nose.id === user.avatar.nose)[0] : false,
            mouth: getters.avatarAssets.mouth !== undefined ? getters.avatarAssets.mouth.filter(mouth => mouth.id === user.avatar.mouth)[0] : false,
            bg: getters.avatarAssets.colors !== undefined ? getters.avatarAssets.colors.filter(color => color.id === user.avatar.bg)[0] : false,
            /* tool: getters.avatarAssets.tool !== undefined ? getters.avatarAssets.tool.filter(tool => tool.id === user.avatar.tool)[0] : false, */
            title: getters.avatarAssets.body !== undefined ? getters.avatarAssets.body.filter(body => body.id === user.defaultAvatar)[0].title : false
          }
        } catch (e) {
          user.avatarData = {
            body: false,
            horn: false
          }
        }
      } else {
        user.avatarData = {
          body: false,
          horn: false
        }
      }
      return user
    })
  },
  completedTopics (state) {
    return state.currentUser.completedTopics
  },
  completedChallenges (state) {
    return state.currentUser.completedChallenges
  },
  answeredPolls (state) {
    return state.currentUser.answeredPolls
  },
  codeChallenge (state) {
    return state.code_challenge
  },
  accessToken (state) {
    return state.currentUser.access_token !== null ? { Authorization: 'Bearer ' + state.currentUser.access_token } : {}
  },
  lockedMissions (state, getters) {
    if (getters.currentUser.avatarSet) {
      const missions = getters.missions.filter(mission => mission.topics.map(topic => topic.id).filter(id => !state.currentUser.completedTopics.includes(id)).length === mission.topics.length)
      const lastUnlockedMission = getters.missions.filter(mission => !missions.includes(mission)).slice(-1)[0]
      if (lastUnlockedMission && lastUnlockedMission.topics.filter(topic => !topic.completed).length === 0) {
        return missions.slice(1)
      }
      if (missions.length === getters.missions.length) {
        return missions.slice(1)
      }
      return missions
    }
    return getters.missions
  },
  lockedMissionsSlug (state, getters) {
    return getters.lockedMissions.map(mission => mission.slug)
  },
  avatarSet (state) {
    return state.currentUser.avatarSet
  },
  missionsLockStatus (state, getters) {
    return getters.missions.map(mission => {
      let lock = 0
      mission.topics = mission.topics.map(topic => {
        if (getters.lockedMissionsSlug.includes(mission.slug)) {
          topic.locked = true
        } else {
          topic.locked = false
          if (!topic.completed) {
            if (lock > 0) {
              topic.locked = true
            }
            lock++
          }
        }
        return topic
      })
      mission.completed = mission.topics.filter(topic => topic.completed).length === mission.topics.length
      mission.locked = getters.lockedMissionsSlug.includes(mission.slug)
      return mission
    })
  },
  avatarDirt (state) {
    return state.currentUserr.avatar.avatarDirt
  },
  gameCompleted (state, getters) {
    return getters.missions.map(mission => mission.topics).flat().length === getters.completedTopics.length
  },
  lastUnlockedMission (state, getters) {
    const unlockedMissions = getters.missionsLockStatus.filter(mission => !mission.locked)
    return unlockedMissions.length ? unlockedMissions[unlockedMissions.length - 1] : false
  },
  shouldWashAvatar (state, getters) {
    let shouldWash = false
    try {
      shouldWash = parseInt(getters.currentUser.avatar.avatarDirt.replace(/\D/g, '')) === 3
    } catch (e) {
      shouldWash = getters.currentUser.avatar.avatarDirt === 3
    }
    return shouldWash
  }
}

const actions = {
  register ({ commit }, userData) {
    return api.register(userData).then((data) => {
      if (data === 'profanity') {
        return data
      }
      return data
    })
  },
  loginOAuth (context, data) {
    context.commit('setUser', processUserData(data.userData, data.data))
  },
  login ({ commit }, userData) {
    return api.login(userData).then((data) => {
      if (data) {
        if (data === 'verify') {
          return data
        }
        if (data.code_challenge) { // oAuth flow
          commit('setCodeChallenge', data.code_challenge)
          window.location.href = data.url
          return 'auth'
        } else {
          commit('setUser', processUserData(userData, data))
        }
        return true
      } else {
        return false
      }
    })
  },
  switchUser ({ commit }, userData) {
    commit('setUser', userData) // to add other user logic
  },
  logOut ({ commit }) {
    commit('setUser', null)
  },
  logOutAll ({ commit }) {
    commit('logOutUsers')
  },
  activateMessage (context) {
    context.commit('setAvatarElementUnlocked', { key: 'avatar0', state: true })
    context.commit('setAvatarElementUnlocked', { key: 'avatar1', state: true })
  },
  setCompletedTopic (context, data) {
    context.commit('setCompletedTopic', data.topic_id)
    api.saveProgress({
      progress: context.state.currentUser.completedTopics,
      uid: context.state.currentUser.uid,
      mid: data.mid,
      topic_id: data.topic_id,
      topic_rating_before: context.getters.topicRating.before,
      topic_rating_after: context.getters.topicRating.after,
      code: context.getters.countryCode
    }, context.getters.accessToken).then(() => {
      if (context.getters.completedTopics.length % 3 === 0) {
        context.commit('setAvatarElementUnlocked', { key: 'avatar0', state: true })
        context.commit('setAvatarElementUnlocked', { key: 'avatar1', state: true })
      }
    })
    context.commit('clearTopicRating')
  },
  setCompletedChallenge (context, data) {
    let mode = 1
    if (context.state.currentUser.completedChallenges.filter(c => c === data).length === 0) {
      context.commit('setCompletedChallenge', {
        mode,
        id: data
      })
      context.commit('setAvatarElementUnlocked', { key: 'avatar0', state: true })
      context.commit('setAvatarElementUnlocked', { key: 'avatar2', state: true })
    } else {
      mode = 0
      context.commit('setCompletedChallenge', {
        mode,
        id: data
      })
    }
    api.saveChallenge({
      progress: context.state.currentUser.completedTopics,
      uid: context.state.currentUser.uid,
      topic_id: data,
      mode: mode
    }, context.getters.accessToken)
  },
  setAvatar (context, data) {
    context.commit('setAvatar', data)
    api.saveAvatar({
      avatar: context.state.currentUser.avatar,
      uid: context.state.currentUser.uid
    }, context.getters.accessToken)
  },
  setGuide (context) {
    if (context.getters.currentUser.guideActive) {
      context.commit('setDashboardGuide', { active: true, step: 0 })
    }
  },
  answerPoll (context, data) {
    context.commit('setAnsweredPoll', data.id)
    return api.sendPollAnswer({
      uid: context.state.currentUser.uid,
      answer: data.answer,
      pollId: data.id
    }, context.getters.accessToken)
  },
  setAvatarDirt (context, data) {
    context.commit('setAvatarDirt', data)
    api.saveAvatar({
      avatar: context.state.currentUser.avatar,
      uid: context.state.currentUser.uid
    }, context.getters.accessToken)
  }
}

const mutations = {
  logOutUsers (state) {
    state.users = []
    state.currentUser = {
      avatar: {
        body: null,
        horn: null,
        nose: null,
        mouth: null,
        bg: null,
        tool: null
      },
      name: '',
      // email: '',
      uid: '',
      active: false,
      completedTopics: [],
      completedChallenges: [],
      answeredPolls: [],
      access_token: null,
      refresh_token: null
    }
  },
  setUser (state, data) {
    if (data === null) {
      const users = state.users.filter(user => user.uid !== state.currentUser.uid)
      state.users = users
      state.currentUser = {
        avatar: {
          body: null,
          horn: null,
          nose: null,
          mouth: null,
          bg: null,
          tool: null,
          avatarDirt: 0
        },
        avatarSet: false,
        name: '',
        // email: '',
        uid: '',
        hash: false,
        active: false,
        completedTopics: [],
        completedChallenges: [],
        answeredPolls: [],
        access_token: null,
        refresh_token: null,
        guideActive: false
      }
      // state.users = []
    } else {
      state.users = state.users.map(user => {
        if (user.uid === state.currentUser.uid) {
          user.completedTopics = state.currentUser.completedTopics
          user.completedChallenges = state.currentUser.completedChallenges
          user.answeredPolls = state.currentUser.answeredPolls
          user.avatar = state.currentUser.avatar
          user.access_token = state.currentUser.access_token
          user.refresh_token = state.currentUser.refresh_token
          user.guideActive = state.currentUser.guideActive
        }
        return user
      }) // before setting the user save the progress and avatar
      state.currentUser.name = data.name
      // state.currentUser.email = data.email
      state.currentUser.uid = data.uid
      state.currentUser.hash = data.hash
      state.currentUser.defaultAvatar = data.defaultAvatar
      state.currentUser.active = true
      state.currentUser.access_token = data.access_token
      state.currentUser.refresh_token = data.refresh_token

      if (Array.isArray(data.completedTopics)) {
        state.currentUser.completedTopics = data.completedTopics
      } else {
        state.currentUser.completedTopics = []
      }

      if (Array.isArray(data.completedChallenges)) {
        state.currentUser.completedChallenges = data.completedChallenges
      } else {
        state.currentUser.completedChallenges = []
      }

      if (Array.isArray(data.answeredPolls)) {
        state.currentUser.answeredPolls = data.answeredPolls
      } else {
        state.currentUser.answeredPolls = []
      }

      state.currentUser.avatarSet = false
      if (data.avatar !== undefined) {
        state.currentUser.avatar = data.avatar
      } else {
        state.currentUser.avatar = undefined
      }

      if (typeof data.avatar === 'object') {
        if (Object.values(data.avatar).slice(0, -1).filter(el => el !== null).length > 0) {
          state.currentUser.avatarSet = true
        }
      }

      if (state.users.filter(user => user.uid === data.uid).length === 0) {
        delete data.email
        state.users.push(data)
      }
      state.users = state.users.map(user => {
        if (user.uid !== state.currentUser.uid) {
          user.active = false
        }
        return user
      })
    }
  },
  setAvatar (state, data) {
    state.currentUser.avatar = data
    state.currentUser.avatarSet = false
    if (typeof state.currentUser.avatar === 'object') {
      if (Object.values(state.currentUser.avatar).slice(0, -1).filter(el => el !== null).length > 0) {
        state.currentUser.avatarSet = true
      }
    }
    state.users = state.users.map(user => {
      if (user.uid === state.currentUser.uid) {
        user.avatar = data
        user.avatarSet = false
        if (typeof user.avatar === 'object') {
          if (Object.values(user.avatar).slice(0, -1).filter(el => el !== null).length > 0) {
            user.avatar.avatarSet = true
          }
        }
      }
      return user
    })
  },
  setCompletedTopic (state, data) {
    /* typeof data === 'number' &&  */
    if (state.currentUser.completedTopics.filter(t => t === data).length === 0) {
      state.currentUser.completedTopics.push(data)
    }
  },
  setCompletedChallenge (state, data) {
    if (data.mode === 1) {
      if (state.currentUser.completedChallenges.filter(c => c === data.id).length === 0) {
        state.currentUser.completedChallenges.push(data.id)
      }
    } else {
      state.currentUser.completedChallenges = state.currentUser.completedChallenges.filter(c => c !== data.id)
    }
  },
  setAnsweredPoll (state, id) {
    state.currentUser.answeredPolls.push(id)
  },
  setCodeChallenge (state, data) {
    state.code_challenge = data
  },
  resetCodeChallenge (state) {
    state.code_challenge = null
  },
  disableGuide (state) {
    state.currentUser.guideActive = false
  },
  setGuideActive (state) {
    state.currentUser.guideActive = true
  },
  setAvatarDirt (state, data) {
    state.currentUser.avatar.avatarDirt = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
