<template>
    <div id="app" :class="[$route.name, missionId, ['Word', 'Quiz', 'Phrase'].includes($route.name) ? 'game' : '']" style="overflow-y: hidden">
      <leaving-site-modal></leaving-site-modal>
      <div class="loading text-center" v-show="loading">
            <span style="margin:0 auto" class="animate__animated animate__pulse animate__infinite"><img src="./assets/img/logo.png"></span>
        </div>
        <b-navbar v-if="$route.name !== 'Dashboard' && !hideHeaderFooter" type="light" class="top-navbar" id="navbar">
            <b-nav class="header-left-nav" order="1" order-md="1" order-lg="1" order-xl="1" v-if="this.$route.name !== 'Clarinha'">
                <b-nav-text>
                    <div class="mission-box" style="text-transform: uppercase">
                        {{ $t('nav.changemakes_on_mission') }}
                        <span class="user-nr">
                        {{ totalUsers }}
                        </span>
                    </div>
                </b-nav-text>
            </b-nav>
            <b-nav class="mx-auto logo-col" order="3" order-md="3" order-lg="2" order-xl="2">
                <b-nav-text class="text-center"><router-link :to="active ? { name: 'NewDashboard' } : '/'">
                    <img src="./assets/img/logo.png">
                    <div class="mission-logo" v-if="this.$route.name === 'Dashboard'"><img src="./assets/img/mission.png"></div>
                </router-link></b-nav-text>
            </b-nav>
            <!--<language-switch></language-switch>-->
            <user-switch></user-switch>
        </b-navbar>
        <div class="content-wrapper" id="mainContent" ref="app" >
            <router-view/>
            <!--<cookie></cookie>-->
        </div>
        <b-navbar v-if="!hideHeaderFooter" type="light" class="footer site-footer" id="footer">
            <span class="dirt-logo"></span>
            <topic-progress v-if="show"></topic-progress>
            <div class="footer-bg" v-if="['Topic', 'Challenge', 'Quiz', 'Reward', 'Phrase', 'Word'].includes($route.name)">
                <router-link :to="{ name: 'MissionTopics', query: { id: $route.query.mid } }" class="back back-topic">Back to Topics</router-link>
                <router-link :to="{ name: 'Dashboard' }" class="back">MISSION DASHBOARD</router-link>
            </div>
             <b-nav class="mx-auto">
                <b-nav-item href="https://www.persil.com/uk/home.html" target="_blank">Persil.com</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'cookie-policy-uk' } }">Cookie policy</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'privacy-policy' } }">Privacy policy</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'terms-of-use' } }">Terms of use</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'legal' } }">Legal</b-nav-item>
             </b-nav>
        </b-navbar>
        <!--<div class="register-background"></div>-->
    </div>
</template>
<script>
import UserSwitch from './components/UserSwitch'
// import LanguageSwitch from './components/LanguageSwitch'
import TopicProgress from './components/TopicProgress'
// import Cookie from './components/Cookie'
import LeavingSiteModal from './components/LeavingSiteModal'
import { mapActions, mapGetters } from 'vuex'
import resize from './mixins/resize'
import isMobile from 'is-mobile'

export default {
  mixins: [resize],
  components: {
    TopicProgress,
    UserSwitch,
    LeavingSiteModal
    // LanguageSwitch
    // Cookie
  },
  name: 'App',
  mounted () {
    this.setupData()
    this.setupUserData()
    setInterval(() => {
      this.setUsersProgressFeed()
      this.setTotalUsers()
    }, 1000 * 30)

    this.$root.$on('titleChanged', title => {
      if (typeof window.ga === 'function') {
        if (typeof window.ga.getAll === 'function') {
          const tracker = window.ga.getAll()[0]
          if (tracker) {
            tracker.send('pageview')
          }
        }
      }
    })

    window.activeMessage = this.activateMessage
  },
  methods: {
    ...mapActions(['setupData', 'setupUserData', 'setUsersProgressFeed', 'setTotalUsers', 'activateMessage'])
  },
  computed: {
    ...mapGetters(['totalUsers', 'loading', 'currentUser', 'missions']),
    hideHeaderFooter () {
      return isMobile() && this.$route.name === 'Game'
    },
    show () {
      return ['Topic', 'Challenge', 'Quiz', 'Reward', 'Word', 'Phrase'].includes(this.$route.name)
    },
    active () {
      return this.currentUser.active
    },
    missionId () {
      if (this.missions) {
        const mission = this.missions.filter(mission => mission.title.toLowerCase().replace(' ', '-') === this.$route.params.mission)[0]
        if (mission) {
          return 'mission-' + mission.id
        }
      }
      return null
    }
  },
  watch: {
    loading (loading) {
      if (loading) {
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        document.querySelector('body').style.overflow = 'initial'
      }
    }
  }
}
</script>

<style>
    .footer {
        background-color: #ccced2;
    }

    .top-navbar {
        height: 100px;
    }

    .loading {
        position: fixed;
        z-index: 9999;
        background-color: #1C3660;
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    div.content-wrapper, div.content-wrapper * {
      /* border: 1px solid transparent */
    }
    .site-footer {
      bottom: 0;
      width: 100%
    }
</style>
