import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import data from './modules/data'
import tmpData from './modules/tmpData'
import VuexPersist from 'vuex-persist'

const dataStore = (new VuexPersist({
  modules: ['users', 'data']
})).plugin

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users,
    data,
    tmpData
  },
  plugins: [
    dataStore
  ]
})
