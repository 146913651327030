let resizeTimeout = null

export function resizeElements () {
  const elementId = 'resizeElement'
  const styledId = 'styledElement'
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => {
    try {
      const windowWidth = window.innerWidth
      if (windowWidth < 1025) {
        return 1
      }
      const element = document.getElementById(elementId)
      const styledElement = document.getElementById(styledId)
      if (typeof element === 'undefined' || element === null || typeof styledElement === 'undefined' || styledElement === null) {
        return 1
      }

      document.getElementById(elementId).style.height = 'auto'

      const containerHeight = document.getElementById(elementId).clientHeight
      const containerWidth = document.getElementById(elementId).clientWidth
      var windowHeight = window.innerHeight

      const headerHeight = document.getElementById('navbar').clientHeight

      const footerHeight = document.getElementById('footer').clientHeight
      const containerSpace = windowHeight - headerHeight - footerHeight * 2
      let zoom = containerSpace / containerHeight

      if (windowWidth < containerWidth * zoom) {
        zoom = windowWidth / containerWidth
      }
      const height = containerHeight * zoom

      if (zoom !== 1) {
        document.getElementById(elementId).style.height = height + 'px'
        document.getElementById(styledId).style.transform = 'scale(' + zoom + ')'
        document.getElementById(styledId).style.transformOrigin = 'top center'
        var progressElement = document.getElementById('progress-box')
        if (typeof progressElement !== 'undefined' && progressElement !== null) {
          document.getElementById('progress-box').style.transform = 'scale(' + zoom + ')'
          document.getElementById('progress-box').style.transformOrigin = 'bottom center'
        }
      }
    } catch (e) {
    }
  }, 100)
}

export default {
  mounted () {
    resizeElements()
    window.addEventListener('resize', resizeElements)
    this.$root.$on('topic::stepChanged', resizeElements)
    this.$root.$on('video::playStarted', resizeElements)
  },
  updated () {
    this.$nextTick(() => {
      resizeElements()
    })
  }
}
