const state = {
  topicRating: {
    before: null,
    after: null
  },
  isRegisterTypeKid: true
}

const getters = {
  topicRating (state) {
    return state.topicRating
  },
  isRegisterTypeKid (state) {
    return state.isRegisterTypeKid
  }
}

const mutations = {
  setBefore (state, data) {
    state.topicRating.before = data
  },
  setAfter (state, data) {
    state.topicRating.after = data
  },
  clearTopicRating (state) {
    state.topicRating.before = null
    state.topicRating.after = null
  },
  setRegisterType (state, data) {
    state.isRegisterTypeKid = data
  }
}

export default {
  state,
  getters,
  mutations
}
