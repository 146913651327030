import axios from 'axios/index'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

export default {
  register (data) {
    return http.post('/register_user', data, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  login (data) {
    return http.post('/login_user', data, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  getMissions (params, auth = {}) { // auth api
    const headers = Object.assign({ 'Content-Type': 'application/json' }, auth)
    return http.get('/get_missions', {
      params,
      headers
    }).then(resp => resp.data)
  },
  getAvatarAssets () {
    return http.get('/get_avatar_assets', { 'Content-Type': 'application/json' }).then(resp => resp.data)
  },
  getAvatarQuestions (data) {
    return http.get('/get_avatar_questions', { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  saveProgress (data, auth = {}) { // auth api
    const headers = Object.assign({ 'Content-Type': 'application/json' }, auth)
    return http.post('/save_progress', data, { headers }).then(resp => resp.data)
  },
  saveChallenge (data, auth = {}) { // auth api
    const headers = Object.assign({ 'Content-Type': 'application/json' }, auth)
    return http.post('/save_challenge', data, { headers }).then(resp => resp.data)
  },
  saveAvatar (data, auth = {}) { // auth api
    const headers = Object.assign({ 'Content-Type': 'application/json' }, auth)
    return http.post('/save_avatar', data, { headers }).then(resp => resp.data)
  },
  getTotalUsers () {
    return http.get('/get_total_users', {}, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  getLanguages () {
    return http.get('/get_all_languages', {}, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  getTranslation (params) {
    return http.get('/get_ui_translation', {
      params,
      headers: { 'Content-Type': 'application/json' }
    }).then(resp => resp.data)
  },
  getUsersProgressFeed (auth = {}) { // auth api
    const headers = Object.assign({ 'Content-Type': 'application/json' }, auth)
    return http.get('/get_users_progress_feed', { headers }).then(resp => resp.data)
  },
  getTotalUsersByCountry () {
    return http.get('/get_total_users_by_country', { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  getInfoContent (params) {
    return http.get('/get_info_content', {
      params,
      headers: { 'Content-Type': 'application/json' }
    }).then(resp => resp.data)
  },
  sendNotification (data, auth = {}) { // auth api
    const headers = Object.assign({ 'Content-Type': 'application/json' }, auth)
    return http.post('/send_notification', data, { headers }).then(resp => resp.data)
  },
  verifyUser (data) {
    return http.post('/verify_user', data, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  updateNotificationSettings (data) {
    return http.post('/update_notification_settings', data).then(resp => resp.data)
  },
  getNotificationSetting (params) {
    return http.get('/get_notification_settings', { params }).then(resp => resp.data)
  },
  getCountryCode () {
    return http.get(process.env.VUE_APP_GEOIP_COUNTRY_CODE_URL).then(resp => {
      let code = ''
      try {
        code = resp.data.match(/loc=.*/g)[0].replace(/loc=/g, '')
      } catch (e) {}
      return code
    })
  },
  sendPollAnswer (data, auth = {}) {
    const headers = Object.assign({ 'Content-Type': 'application/json' }, auth)
    return http.post('/answer_poll', data, { headers }).then(resp => resp.data)
  },
  getLinks (params) {
    return http.get('/get_links', {
      params,
      headers: { 'Content-Type': 'application/json' }
    }).then(resp => resp.data)
  }
}
