<script>
export default {
  name: 'VueTitle',
  props: ['title'],
  watch: {
    title: {
      immediate: true,
      handler () {
        document.title = 'Dirt Is Good Academy' + (this.title ? ' - ' + this.title : '')
        this.$root.$emit('titleChanged', document.title)
      }
    }
  },
  render () {
    return ''
  }
}
</script>
