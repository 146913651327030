import api from '../../api'
import { loadLanguageAsync } from '../../i18n'
import wb from '../../registerServiceWorker'

const state = {
  avatarAssets: {
    horn: [],
    nose: [],
    mouth: [],
    colors: [],
    body: []
  },
  avatarQuestions: null,
  type: 0,
  missions: [],
  totalUsers: 0,
  languages: [],
  currentLanguage: {
    code: 'en',
    flag: '',
    name: 'English'
  },
  loading: false,
  usersProgressFeed: [],
  totalUsersByCountry: [],
  runAnimations: false,
  countryCode: '',
  dashboardGuide: {
    active: false,
    step: 0
  },
  avatarElementUnlocked: {
    avatar0: false,
    avatar1: false,
    avatar2: false
  }
}

const cacheMissionsAssets = missions => {
  const urlsToCache = []
  missions.forEach(mission => {
    if (mission.cover) {
      urlsToCache.push(mission.cover)
    }
    if (mission.icon) {
      urlsToCache.push(mission.icon)
    }

    if (mission.mission_badge) {
      urlsToCache.push(mission.mission_badge)
    }
    if (mission.mission_badge_active) {
      urlsToCache.push(mission.mission_badge_active)
    }

    if (mission.mission_title_image) {
      urlsToCache.push(mission.mission_title_image)
    }

    if (Array.isArray(mission.topics)) {
      mission.topics.forEach(topic => {
        if (topic.challenge_bg_left) {
          urlsToCache.push(topic.challenge_bg_left)
        }

        if (topic.challenge_bg_left_mobile) {
          urlsToCache.push(topic.challenge_bg_left_mobile)
        }

        if (topic.challenge_bg_right) {
          urlsToCache.push(topic.challenge_bg_right)
        }

        if (topic.topic_image) {
          urlsToCache.push(topic.topic_image)
        }

        if (topic.topic_rating_bg_after) {
          urlsToCache.push(topic.topic_rating_bg_after)
        }

        if (topic.topic_rating_bg_after_mobile) {
          urlsToCache.push(topic.topic_rating_bg_after_mobile)
        }

        if (topic.topic_rating_bg_before) {
          urlsToCache.push(topic.topic_rating_bg_before)
        }

        if (topic.topic_rating_bg_before_mobile) {
          urlsToCache.push(topic.topic_rating_bg_before_mobile)
        }

        if (topic.badge.url) {
          urlsToCache.push(topic.badge.url)
        }
      })
    }
  })

  if (wb) {
    wb.messageSW({
      type: 'CACHE_URLS',
      payload: { urlsToCache }
    })
  }
}

const getters = {
  missions (state) {
    return state.missions
  },
  avatarQuestions (state) {
    return state.avatarQuestions
  },
  avatarAssets (state, getters) {
    const assets = state.avatarAssets
    if (Array.isArray(assets.tool)) {
      assets.tool = assets.tool.concat(getters.unlockedAvatarTools)
    }
    return assets
  },
  topicRewards (state) {
    return state.missions.map(mission => mission.topics).flat().map(topic => {
      return {
        id: topic.id,
        reward: topic.reward
      }
    })
  },
  topicNames (state) {
    return state.missions.map(mission => mission.topics).flat().map(topic => {
      return {
        id: topic.id,
        title: topic.title_challenge
      }
    })
  },
  totalTopics (state) {
    return state.missions.map(mission => mission.topics).flat().length
  },
  totalUsers (state) {
    return state.totalUsers
  },
  currentLanguage (state) {
    return state.currentLanguage
  },
  languages (state) {
    return state.languages.filter(lang => lang.code !== state.currentLanguage.code)
  },
  loading (state) {
    return state.loading
  },
  getAvatarType (state) {
    return state.type
  },
  allTopics (state) {
    return state.missions.map(mission => mission.topics).flat()
  },
  usersProgressFeed (state) {
    return state.usersProgressFeed
  },
  totalUsersByCountry (state) {
    return state.totalUsersByCountry
  },
  countryCode (state) {
    return state.countryCode
  },
  dashboardGuide (state) {
    return state.dashboardGuide
  },
  avatarElementUnlocked (state) {
    return state.avatarElementUnlocked
  },
  unlockedAvatarTools (state, getters) {
    return state.missions.map(mission => mission.topics).flat().filter(topic => getters.completedChallenges.includes(topic.id)).map(topic => {
      return {
        id: topic.id,
        image: topic.changemaker_tool_image,
        title: topic.changemaker_tool_name
      }
    })
  }
}

const actions = {
  setupUserData (context) {
    if (context.getters.currentUser.active) {
      context.commit('setLoading', true)
      context.dispatch('clearAvatarElementUnlocked')
      return Promise.all(
        [
          context.dispatch('getMissions'),
          context.dispatch('setUsersProgressFeed')
        ]).then(() => {
        context.commit('setLoading', false)
      }).catch(() => {
        context.dispatch('logOut')
      })
    }
  },
  setupData (context) {
    context.commit('setLoading', true)
    context.dispatch('setLanguageTranslation').then(() => {
      if (context.getters.missions.length && context.getters.avatarAssets.horn.length && context.getters.avatarQuestions.length) {
        context.commit('setLoading', false)
      }
    })
    return Promise.all(
      [
        context.dispatch('getAvatarAssets'),
        context.dispatch('getAvatarQuestions'),
        context.dispatch('setTotalUsers'),
        context.dispatch('setLanguages'),
        context.dispatch('setLanguageTranslation'),
        context.dispatch('getCountyCode')
      ]).then(() => {
      context.commit('setLoading', false)
      // context.dispatch('setTotalUsersByCountry') // used by the globe page, right now disabled
    })
  },
  setLanguageTranslation (context) {
    return loadLanguageAsync(context.state.currentLanguage.code)
  },
  getMissions (context) {
    return api.getMissions({ code: context.state.currentLanguage.code }, context.getters.accessToken).then(missions => {
      missions = missions.map(mission => {
        mission.topics = mission.topics.map(topic => {
          topic.completed = context.getters.completedTopics.includes(topic.id)
          return topic
        })
        return mission
      })
      context.commit('setMissions', missions)
      cacheMissionsAssets(missions)
    })
  },
  getAvatarAssets (context) {
    return api.getAvatarAssets().then(data => {
      context.commit('setAvatarAssets', data)
    })
  },
  getAvatarQuestions ({ commit }) {
    return api.getAvatarQuestions().then(data => {
      commit('setAvatarQuestions', data)
    })
  },
  setTotalUsers ({ commit }) {
    api.getTotalUsers().then(total => {
      if (total) {
        commit('setTotalUsers', total)
      }
    })
  },
  setLanguages ({ commit }) {
    api.getLanguages().then(langs => {
      if (langs) {
        commit('setLanguages', langs)
      }
    })
  },
  setCurrentLanguage (context, data) {
    context.commit('setLoading', true)
    return Promise.all([
      context.commit('setLanguage', data),
      context.dispatch('getMissions'),
      loadLanguageAsync(data.code)]
    ).then(() => {
      context.commit('setLoading', false)
    })
  },
  setUsersProgressFeed (context) {
    if (context.getters.currentUser.active) {
      api.getUsersProgressFeed(context.getters.accessToken).then(users => {
        if (users) {
          context.commit('setUsersProgressFeed', users)
        }
      })
    }
  },
  setTotalUsersByCountry ({ commit }) {
    api.getTotalUsersByCountry().then(totals => {
      if (totals) {
        commit('setTotalUsersByCountry', totals)
      }
    })
  },
  getCountyCode (context) {
    if (context.getters.countryCode === '') {
      api.getCountryCode().then(code => {
        context.commit('setCountryCode', code)
      })
    }
  },
  updateUserTopic (context, data) {
    context.commit('updateUserTopic', data)
  },
  clearAvatarElementUnlocked (context) {
    Object.keys(context.getters.avatarElementUnlocked).forEach((key) => {
      context.getters.avatarElementUnlocked[key] = false
    })
  }
}

const mutations = {
  setMissions (state, data) {
    state.missions = data
  },
  setAvatarAssets (state, data) {
    if (data.body.length) {
      let key
      const keys = Object.keys(data.defaults)
      let n = keys.length
      const newobj = {}
      while (n--) {
        key = keys[n]
        newobj[key.toLowerCase()] = data.defaults[key]
      }
      data.defaults = newobj

      state.avatarAssets = {
        horn: data.parts.filter(part => part.type === 'horn'),
        mouth: data.parts.filter(part => part.type === 'mouth'),
        nose: data.parts.filter(part => part.type === 'nose'),
        body: data.body,
        tool: state.avatarAssets.tool,
        colors: data.colors,
        defaults: data.defaults
      }
    }
  },
  setAvatarQuestions (state, data) {
    if (data.length) {
      state.avatarQuestions = data
    }
  },
  setTotalUsers (state, data) {
    state.totalUsers = data
  },
  setLanguages (state, data) {
    state.languages = data
  },
  setLanguage (state, data) {
    state.currentLanguage = data
  },
  setLoading (state, data) {
    state.loading = data
  },
  setAvatarType (state, type) {
    state.type = type
  },
  setUsersProgressFeed (state, data) {
    state.usersProgressFeed = data
  },
  setTotalUsersByCountry (state, data) {
    state.totalUsersByCountry = data
  },
  setCountryCode (state, data) {
    state.countryCode = data
  },
  updateUserTopic (state, data) {
    state.missions = state.missions.map(mission => {
      if (mission.slug === data.mission) {
        mission.topics = mission.topics.map(topic => {
          if (topic.slug === data.topic) {
            topic.completed = true
          }
          return topic
        })
      }
      return mission
    })
  },
  setDashboardGuide (state, data) {
    state.dashboardGuide = data
  },
  setAvatarElementUnlocked (state, data) {
    if (['avatar0', 'avatar1', 'avatar2'].includes(data.key)) {
      state.avatarElementUnlocked[data.key] = data.state
    }
  },
  setReplay (state, data) {
    state.missions = state.missions.map(mission => {
      if (data.mission && data.mission === mission.slug && mission.completed) {
        mission.replay = true
      }
      mission.topics = mission.topics.map(topic => {
        if (data.topic && data.topic === topic.slug && topic.completed) {
          topic.replay = true
        }
        return topic
      })
      return mission
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
